import React from 'react';
import Spinner from 'react-bootstrap/Spinner'
class SpinnerComponent extends React.Component {
  render() {
      return (
        <div className='d-flex align-items-center justify-content-center' style={{width: "100%", height: "100%", position: "absolute", textAlign: "center", top: "0", left: "0", zIndex: "999", background: "rgba(255,255,255,1)"}}>
          <Spinner style={{width: "5rem", height: "5rem"}} animation="border" size="lg" />
        </div>
      );
  }
}
export default SpinnerComponent;
