import * as React from 'react';
import ReactDOM from "react-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom"

// ** Spinner (Splash Screen)
import Spinner from './components/spinner/Fallback-spinner'

// ** Lazy load app
const LazyApp = React.lazy(() => import('./App'))
 
// ReactDOM.render(<App />, document.getElementById("root"));
// serviceWorker.unregister();

ReactDOM.render( 
<BrowserRouter>
  <React.Suspense fallback={<Spinner />}>
    <LazyApp />
  </React.Suspense>
</BrowserRouter>, document.getElementById("root"))
serviceWorker.unregister()